import React from 'react'
import Layout from '../components/layout/layoutRenter'
import { graphql, Link } from 'gatsby'
import styles from './index.module.scss'
import { RIGHT_ARROW } from '../components/icons'
import Hero from '../components/dashboard/hero'
import HIW from '../components/dashboard/howItWorks'
import BEN from '../components/dashboard/benefits'
import SearchForm from '../components/dashboard/searchForm'
import SEO from '../components/common/seo'
import { DASHBOARD } from '../routes'
import Data from '../components/common/data'
import List from '../components/property/list'
import { getTopProperties } from '../requests/propertyHTTP'

const Index = ({ data: { datoCmsBuyerPage: renter } }) => {
  const error = {
    message: 'No results found'
  }
  const result = {
    properties: [],
    pages: 0
  }
  const PropertiesList = Data(List, { error, result }, () => getTopProperties())
  return (
    <Layout>
      <SEO />
      <Hero />
      <div className="d-flex flex-column py-3">
        <div className=" mb-3">
          <HIW howItWorkList={renter.howItWorkList} />
          <BEN benefits={renter.benefits} />
        </div>
        <SearchForm>
          <h4 className={styles.search_title}>Let’s find your perfect home</h4>
        </SearchForm>
        <div className="">
          <p className={styles.top_search}>Our top picks</p>
          <div className={styles.results}>
            <PropertiesList />
          </div>
          <p className="text-right">
            <Link to={DASHBOARD} className="text-secondary">
              Show me more properties <RIGHT_ARROW />
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query HomePage {
    datoCmsBuyerPage {
      howItWorkList {
        id
        title
        description
      }
      benefits {
        id
        title
        description
      }
    }
  }
`
