import styles from './dashboard.module.scss'
import { ARROW_DOWN } from '../icons'
import React from 'react'
import { Link } from 'gatsby'

const Hero = () => (
  <div className={styles.hero}>
    <div className="position-relative h-100">
      <h1 className={styles.title}>
        <span className="text-white">The future</span> of <br /> property inspections
      </h1>
      <p className={`${styles.message} text-white`}>Self-tour your dream home in your own time, agent-free.</p>
      <p className={styles.arrow}>
        <Link className="btn btn-link" to="#searchForm">
          <ARROW_DOWN />
        </Link>
      </p>
    </div>
  </div>
)

export default Hero
