import { CLOCK, LIKE, MOBILE } from '../icons'
import styles from './dashboard.module.scss'
import Benefits from '../benefits/benefits'
import React from 'react'

const BEN = ({ benefits }) => {
  benefits[0].icon = <MOBILE />
  benefits[1].icon = <CLOCK />
  benefits[2].icon = <LIKE />
  return (
    <>
      <button
        className={`${styles.btn_info} btn btn-block text-left mb-3 d-lg-none`}
        type="button"
        data-toggle="collapse"
        data-target="#benefits"
        aria-expanded="false"
        aria-controls="benefits"
      >
        Uthru Benefits?
      </button>
      <div className="collapse d-lg-block" id="benefits">
        <div className={`${styles.ben_card} card card-body`}>
          <Benefits benefits={benefits} classes={styles} />
        </div>
      </div>
    </>
  )
}

export default BEN
