import styles from './dashboard.module.scss'
import HowItWork from '../howItWorks/howItWorks'
import React from 'react'

const HIW = ({ howItWorkList }) => (
  <>
    <button
      className={`${styles.btn_info} btn btn-block text-left mb-3 d-lg-none`}
      type="button"
      data-toggle="collapse"
      data-target="#how-it-works"
      aria-expanded="false"
      aria-controls="how-it-works"
    >
      How Uthru works?
    </button>
    <div className="collapse d-lg-block mt-4 mb-5 position-relative" id="how-it-works">
      <div className="card card-body shadow mb-3">
        <HowItWork steps={howItWorkList} title="Here's how it works" classes={styles}>
          <p className={styles.about_text}>
            Finding your dream home has never been easier or safer. Inspect vacant properties independently, at your
            convenience, with Uthru.
          </p>
        </HowItWork>
      </div>
    </div>
  </>
)

export default HIW
